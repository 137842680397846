body {
    background-color: #dedede;
}

.App {
    padding: 10px;
}

.App .search {
    margin-bottom: 20px;
}

.App .content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.App .card .tag-item {
    display: flex;
    align-items: center;
}

.App .card .tag-item .copy {
    margin-left: 5px;
    color: #096dd9;
    cursor: pointer;
    font-size: 12px;
}

.App .card .tag-item .name {
    font-size: 12px;
    color: #767373;
}

.App .card .tag-item .value {
    display: inline-block;
    font-size: 12px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}